/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h4: "h4",
    p: "p",
    em: "em",
    pre: "pre",
    code: "code",
    strong: "strong",
    ul: "ul",
    li: "li",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h4, null, "AJAX-(Asynchronous, JavaScript, and XML)"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "AJAX is a an approach")), "\n", React.createElement(_components.h4, null, "Functions"), "\n", React.createElement(_components.p, null, "Function expression"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "functionOne = function() {\n  //some code\n};\n\n")), "\n", React.createElement(_components.p, null, "Function declaration"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function functionTwo() {\n  //some code\n};\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Null vs Undefined")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "let x = null;\nlet y = undefined;\n//then\nx == y\n// return true;\n")), "\n", React.createElement(_components.p, null, "Ref: Abstract equality comparison ", React.createElement("br"), " https://www.ecma-international.org/ecma-262/6.0/#sec-abstract-equality-comparison"), "\n", React.createElement(_components.h4, null, "Callback functions"), "\n", React.createElement(_components.p, null, "a function that is passed into another function as a parameter then invoked by another function."), "\n", React.createElement(_components.h4, null, "Higher order function"), "\n", React.createElement(_components.p, null, "a function that accepts a callback as a parameter"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "EXAMPLE")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function callback(){\n  console.log(\"Coming from callback\");\n}\n\nfunction higherOrder(fn){\n  console.log(\"About to call callback\");\n  fn(); // Callback function is invoked\n  console.log(\"Callback has been invoked\");\n}\n\nhigherOrder(callback);\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Use Cases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Advanced array methods"), "\n", React.createElement(_components.li, null, "browser Events"), "\n", React.createElement(_components.li, null, "AJAX requests"), "\n", React.createElement(_components.li, null, "React development"), "\n"), "\n", React.createElement(_components.h4, null, "For Each function"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Simple Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "var arr = [1,2,3,4,5,6];\nforEach(arr, function(number){\n  console.log(number*2);\n});\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Generalized")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "\nfunction forEach(array, callback){\n  // To be implemented\n}\n// Callback signature\nfunction callback(curElement, currentIndex, array){\n  // Implemented by the caller of forEach\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "EXAMPLE")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "var strings = [\"my\", \"forEach\", \"example\"];\n\nvar result = \"\";\nforEach(strings, function(str, index, array){\n  if (array.length - 1 !== index){\n    result += str + \" \";\n  } else {\n    result += str + \"!!!\";\n  }\n});\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "forEach logic EXAMPLE")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "\n\nfunction forEach(arr, callback){\n  for(let i = 0; i < arr.length; i++){\n    callback(arr[i], i, arr);\n  }\n}\n")), "\n", React.createElement(_components.h4, null, "findIndex"), "\n", React.createElement(_components.p, null, "Returns the index of the first element in the array for which the callback returns a truthy value. -1 is returned if the callback never returns a truthy value."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function findIndex(array, callback){\n  // findIndex code to be implemented\n}\n\nfunction callback(curElement, curIndex, array){\n  // callback implemented by caller of function\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "findIndex logic Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function findIndex(arr, callback){\n  for(let i = 0; i < arr.length; i++){\n    if(callback(arr[i], i, arr)){\n      return i;\n    }\n  }\n  return -1;\n}\n")), "\n", React.createElement(_components.h2, null, "Styling and Code Quality"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "To Check out")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rwaldron/idiomatic.js/"
  }, "Indomatic.js")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://prep.hackreactor.com/courses/hack-reactor-prep/lectures/1792327"
  }, "Code Style Guide from HackReactor")), "\n"), "\n", React.createElement(_components.h2, null, "Resources and Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/getify/You-Dont-Know-JS"
  }, "You Don't Know JS - github")), "\n", React.createElement(_components.p, null, "Notes within this doc have been drawn from the following sources:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://ejs.co/"
  }, "EJS docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ecma-international.org/ecma-262/6.0/"
  }, "Ecma-international")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
